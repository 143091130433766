<template>
  <div :class="{ 'is-flex': getModuleConfig('card_layout') === 'flex' }">
    <div class="top">
      <Header
        :page-title="getModuleConfig('page_title')"
        :page-image="getModuleConfig('page_image')"
        :header-section="headerSection"
        :meta="meta"
      />
      <div class="p-3">
        <div
          v-if="getModuleConfig('member_card_section.enable') == true"
          class="card"
          :class="`layout-${getModuleConfig(
            'member_card_section.card_layout'
          )}`"
          :style="cardBackground"
        >
          <div class="card__logo">
            <img
              v-if="getModuleConfig('member_card_section.display_logo')"
              :src="getModuleConfig('member_card_section.logo')"
            />
          </div>

          <div
            class="card__name"
            v-if="getModuleConfig('member_card_section.display_name') == true"
          >
            {{ user.name }}
          </div>
          <div class="card__avator">
            <img src="https://placekitten.com/300/300" class="rounded-circle" />
          </div>
          <div class="card__level">VIP 會員</div>

          <div class="card__point">
            <div
              v-if="
                getModuleConfig('member_card_section.display_points') == true
              "
              class="card__point__title"
            >
              {{ displayPointsName }}
            </div>
            <div
              v-if="
                getModuleConfig('member_card_section.display_points') == true
              "
              class="card__point__number"
            >
              {{ userPoints }}
            </div>
          </div>

          <div
            class="card__barcode"
            v-if="
              getModuleConfig('member_card_section.display_barcode') == true &&
              getModuleConfig('member_card_section.card_layout') != 60
            "
          >
            <!-- <img class="" src="./images/barcode.png" /> -->
            <p v-if="showBarcodeError" class="s-text-danger">
              barcode 產生錯誤，請聯絡相關人員
            </p>
            <img v-else id="card__barcode-img" :src="barcode" />
          </div>
        </div>

        <div
          class="card__barcode my-4"
          v-if="
            getModuleConfig('member_card_section.card_layout') == 60 &&
            getModuleConfig('member_card_section.display_barcode') == true
          "
        >
          <!-- <img class="" src="./images/barcode.png" /> -->
          <p v-if="showBarcodeError" class="s-text-danger">
            barcode 產生錯誤，請聯絡相關人員
          </p>
          <img v-else id="card__barcode-img" :src="barcode" />
        </div>
      </div>
    </div>

    <div
      v-for="(section, index) in sections"
      :key="'section' + index"
      class="mb-1"
    >
      <!-- menu_icon_section start -->
      <div v-if="section.key === 'menu_icon_section'" class="p-2 bg-white">
        <div class="function">
          <div
            v-for="i in section.menu_items"
            class="function__item p-2"
            :style="{ 'flex-basis': `${100 / section.column_number}%` }"
            :key="i.title"
          >
            <template v-if="i.url !== undefined">
              <a target="_blank" :href="i.url">
                <div
                  v-if="
                    i.icon_url == undefined ||
                    i.icon_url.split('.').pop() == 'svg'
                  "
                  class="function__icon__wrapper"
                >
                  <img
                    class="function__icon"
                    :style="{
                      maskImage: getIconMask(i),
                      webkitMaskImage: getIconMask(i),
                    }"
                  />
                </div>
                <img v-else class="function__icon" :src="i.icon_url" />

                <div class="function__title">{{ i.title }}</div>
              </a>
            </template>
            <template v-else>
              <router-link
                :to="
                  moduleMap.map((x) => x.type).includes(i.type)
                    ? {
                        name: moduleMap.find((x) => x.type == i.type).value,
                        params: {
                          org_code:
                            $route.params.orgCode || $route.params.org_code,
                          orgCode:
                            $route.params.orgCode || $route.params.org_code,
                        },
                      }
                    : '/'
                "
              >
                <div
                  v-if="
                    i.icon_url == undefined ||
                    i.icon_url.split('.').pop() == 'svg'
                  "
                  class="function__icon__wrapper"
                >
                  <img
                    class="function__icon"
                    :style="{
                      maskImage: getIconMask(i),
                      webkitMaskImage: getIconMask(i),
                    }"
                  />
                </div>
                <img v-else class="function__icon" :src="i.icon_url" />

                <div class="function__title">{{ i.title }}</div>
              </router-link>
            </template>
          </div>
        </div>
      </div>
      <!-- menu_icon_section end -->

      <!-- menu_list_section start -->
      <div v-if="section.key === 'menu_list_section'">
        <div class="menu">
          <span v-for="i in section.menu_items" :key="i.title">
            <router-link
              v-if="i.url == undefined"
              class="menu__item"
              :to="
                moduleMap.map((x) => x.type).includes(i.type)
                  ? {
                      name: moduleMap.find((x) => x.type == i.type).value,
                      params: { org_code: $route.params.orgCode },
                    }
                  : '/'
              "
            >
              <span class="menu__title">{{ i.title }}</span>
              <svg
                width="18"
                height="19"
                viewBox="0 0 18 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.2773 9.85059C13.2773 9.65723 13.1982 9.48144 13.0576 9.34961L6.44824 3.00391C6.32519 2.88086 6.17578 2.81055 5.99121 2.81055C5.62207 2.81055 5.34082 3.0918 5.34082 3.45215C5.34082 3.62793 5.41113 3.79492 5.52539 3.91797L11.6953 9.8418L5.52539 15.7744C5.41113 15.8975 5.34082 16.0557 5.34082 16.2314C5.34082 16.6006 5.62207 16.8818 5.99121 16.8818C6.17578 16.8818 6.3252 16.8027 6.44824 16.6885L13.0576 10.334C13.1982 10.1934 13.2773 10.0352 13.2773 9.85059Z"
                  fill="#C4C4C4"
                />
              </svg>
            </router-link>
            <a v-else class="menu__item" :href="i.url" target="_blank">
              <span class="menu__title">{{ i.title }}</span>
              <svg
                width="18"
                height="19"
                viewBox="0 0 18 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.2773 9.85059C13.2773 9.65723 13.1982 9.48144 13.0576 9.34961L6.44824 3.00391C6.32519 2.88086 6.17578 2.81055 5.99121 2.81055C5.62207 2.81055 5.34082 3.0918 5.34082 3.45215C5.34082 3.62793 5.41113 3.79492 5.52539 3.91797L11.6953 9.8418L5.52539 15.7744C5.41113 15.8975 5.34082 16.0557 5.34082 16.2314C5.34082 16.6006 5.62207 16.8818 5.99121 16.8818C6.17578 16.8818 6.3252 16.8027 6.44824 16.6885L13.0576 10.334C13.1982 10.1934 13.2773 10.0352 13.2773 9.85059Z"
                  fill="#C4C4C4"
                />
              </svg>
            </a>
          </span>
        </div>
      </div>
      <!-- menu_list_section end -->

      <!-- 會員資料 member_detail_section start -->
      <div v-if="section.key === 'member_detail_section'" class="detail">
        <div
          v-for="(fields, index) in groupedDetailFields"
          :key="'row_field' + index"
          class="detail__row detail__row--two-col"
        >
          <div
            v-for="(field, index2) in fields"
            :key="'field' + index2"
            class="detail__item"
          >
            <div class="detail__title">{{ field.title }}</div>
            <div v-if="field.type !== 'address'" class="detail__value">
              {{
                field.is_extra
                  ? user.extra[field.field_name]
                  : user[field.field_name]
              }}
            </div>
            <div v-if="field.type == 'address'" class="detail__value">
              {{ address }}
            </div>
          </div>
        </div>
      </div>
      <!-- 會員資料 member_detail_section end -->

      <!-- 點數 member_point_section start -->
      <div v-if="section.key === 'member_point_section'">
        <div class="points-list">
          <div class="points-list__item" v-for="item in points" :key="item.id">
            <div class="points-list__title">2022/12/31 到期點數</div>
            <div class="points-list__point">{{ item.point }}</div>
          </div>
        </div>
        <div>
          <Pagination v-model="page" :totalPage="points.length"></Pagination>
        </div>
      </div>
      <!-- 點數 member_point_section end -->
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Page/Liff/Shared/Pagination";
import Header from "@/components/Page/Liff/Shared/HeaderV2";
import { mapGetters } from "vuex";
import bwipjs from "bwip-js";
import _ from "lodash";
import pointApi from "@/apis/liff/v2/point";
import moduleMap from "@/mixins/liff/memberCenter/moduleMap";

const requireImage = (path) =>
  require(`@/assets/images/liff/module-icon/${path}`);

export default {
  mixins: [moduleMap],
  components: {
    Pagination,
    Header,
  },
  props: {
    isFlexLayout: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sections: [],
      detail_fields: [],
      showBarcodeError: false,
      barcode: null,
      page: 1,
      userPoints: "-",
      points: [
        {
          id: 1,
          point: 1000,
        },
        {
          id: 2,
          point: 5000,
        },
        {
          id: 3,
          point: 5000,
        },
        {
          id: 4,
          point: 5000,
        },
        {
          id: 5,
          point: 5000,
        },
        {
          id: 6,
          point: 5000,
        },
        {
          id: 7,
          point: 5000,
        },
        {
          id: 8,
          point: 5000,
        },
        {
          id: 9,
          point: 5000,
        },
      ],
    };
  },
  computed: {
    ...mapGetters("liffAuth", ["user"]),
    ...mapGetters({
      meta: "liffGeneral/meta",
    }),
    displayPointsName() {
      return (
        this.getModuleConfig("member_card_section.points_name") ?? "紅利點數"
      );
    },
    address() {
      if (!this.user || !this.user.extra) {
        return null;
      }
      return (
        (this.user.extra.city || "") +
        (this.user.extra.district || "") +
        (this.user.extra.address || "")
      );
    },
    groupedDetailFields() {
      let groupedArray = [];
      let group = [];

      for (let i = 0; i < this.detail_fields.length; i++) {
        if (this.detail_fields[i].type === "address") {
          if (group.length > 0) {
            groupedArray.push(group);
            group = [];
          }
          groupedArray.push([this.detail_fields[i]]);
        } else {
          group.push(this.detail_fields[i]);
          if (group.length === 2) {
            groupedArray.push(group);
            group = [];
          }
        }
      }

      if (group.length > 0) {
        groupedArray.push(group);
      }

      return groupedArray;
    },
    headerSection() {
      return this.getModuleConfig("header_section");
    },
    cardBackground() {
      let style = [];

      if (this.getModuleConfig("member_card_section.display_card_luster")) {
        style.push(
          `linear-gradient(135deg,${this.getModuleConfig(
            "member_card_section.card_luster_left_left"
          )} 0%,${this.getModuleConfig(
            "member_card_section.card_luster_left_right"
          )} 50%,${this.getModuleConfig(
            "member_card_section.card_luster_right_left"
          )} 50%,${this.getModuleConfig(
            "member_card_section.card_luster_right_right"
          )} 100%)`
        );
      } else {
        style.push(
          `linear-gradient(135deg,rgba(255, 255, 255, 0) 0%,rgba(0, 0, 0, .05) 50%,rgba(255, 255, 255, .2) 50%,rgba(255, 255, 255, 0) 100%)`
        );
      }

      if (
        this.getModuleConfig("member_card_section.display_card_picture") &&
        this.getModuleConfig("member_card_section.card_picture_url")
      ) {
        style.push(
          `url('${this.getModuleConfig(
            "member_card_section.card_picture_url"
          )}')`
        );
      }

      return { background: style.join(",") };
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      // 只有當啟用顯示 barcode 時，才 generate barcode
      if (
        this.getModuleConfig("member_card_section.display_barcode") === true
      ) {
        this.generateBarcode();
      }

      if (this.getModuleConfig("member_card_section.display_points") === true) {
        const data = await pointApi.getAvailablePoints();
        this.userPoints = data;
      }

      // 排序 section
      let sectionKeys = [
        "menu_icon_section",
        "menu_list_section",
        "member_card_section",
        "member_point_section",
        "member_detail_section",
      ];
      let sections = [];
      for (let i = 0; i < sectionKeys.length; i++) {
        let config = this.getModuleConfig(sectionKeys[i]);

        config.key = sectionKeys[i];

        if (config.enable === true) {
          sections.push(config);
        }
      }
      this.sections = _.sortBy(sections, "order");

      // 排序 menu_icon_section
      if (this.sections.some((x) => x.key == "menu_icon_section")) {
        let section = this.sections.find((x) => x.key == "menu_icon_section");
        section.menu_items = _.sortBy(section.menu_items, "order");
      }

      // 排序 menu_list_section
      if (this.sections.some((x) => x.key == "menu_list_section")) {
        let section = this.sections.find((x) => x.key == "menu_list_section");
        section.menu_items = _.sortBy(section.menu_items, "order");
      }

      // 排序 detail fields
      let memberDetailSection = this.getModuleConfig("member_detail_section");

      if (memberDetailSection.enable === true) {
        let fields = memberDetailSection.fields.map((field) => {
          let isExtra =
            field.matched_key && field.matched_key.startsWith("extra.");

          return {
            ...field,
            is_extra: isExtra,
            field_name: isExtra
              ? field.matched_key.substring(6)
              : field.matched_key,
          };
        });

        this.detail_fields = _.sortBy(fields, "order");
      }
    },
    generateBarcode() {
      let canvasElement = document.createElement("canvas");

      try {
        bwipjs.toCanvas(canvasElement, {
          bcid: "code128", // Barcode type
          text: this.user.customer_number,
          scale: 2, // 3x scaling factor
          height: 10, // Bar height, in millimeters
          includetext: true, // Show human-readable text
          textxalign: "center", // Always good to set this
        });
        //document.getElementById("card__barcode-img").src =
        this.barcode = canvasElement.toDataURL("image/png");
        this.showBarcodeError = false;
      } catch (e) {
        console.log("barcode generate error", e);
        this.showBarcodeError = true;
      }
    },
    handleEdit() {
      this.$router.push({ name: "LiffMemberCenterEdit" });
    },
    getModuleConfig(key) {
      return this.$store.getters["liffModule/getConfig"]("member_center", key);
    },
    getIcon(path) {
      try {
        return requireImage(path);
      } catch {
        return "";
      }
    },
    getIconMask(i) {
      return `url(${
        i.icon_url == undefined ? this.getIcon(`${i.type}.svg`) : i.icon_url
      })`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "style.scss";
</style>
