<template>
  <div>
    <div v-if="showLoading"><Loading /></div>
    <Header
      :page-title="getModuleConfig('page_title')"
      :page-image="getModuleConfig('page_image')"
      :page-kv-image="getModuleConfig('page_kv_image')"
      :header-section="getModuleConfig('header_section')"
      :meta="meta"
    />

    <div class="page">
      <div v-if="getModuleConfig('form_title')" class="page__title">
        {{ getModuleConfig("form_title") }}
      </div>
      <div class="form">
        <!-- <div class="form__title">
          <svg
            viewBox="0 0 16 16"
            width="1em"
            height="1em"
            focusable="false"
            role="img"
            aria-label="person circle"
            size="sm"
            xmlns="http://www.w3.org/2000/svg"
            :fill="themeColor"
            class="bi-person-circle b-icon bi text-primary"
          >
            <g>
              <path
                d="M13.468 12.37C12.758 11.226 11.195 10 8 10s-4.757 1.225-5.468 2.37A6.987 6.987 0 0 0 8 15a6.987 6.987 0 0 0 5.468-2.63z"
              ></path>
              <path
                fill-rule="evenodd"
                d="M8 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
              ></path>
              <path
                fill-rule="evenodd"
                d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8z"
              ></path>
            </g>
          </svg>
          <span class="ml-1">{{ getModuleConfig('form_title') }}</span>
        </div> -->

        <!-- fields loop start -->
        <div
          v-for="(field, index) in fields.filter((x) => {
            return x.hidden == undefined || !x.hidden;
          })"
          :key="`field.${index}}`"
          class="field"
        >
          <!-- name -->
          <div v-if="field.type === 'name'">
            <label
              class="field__label"
              :class="field.required === true ? 'field--required' : null"
            >
              {{ displayTitle(field, "姓名") }}
            </label>
            <input
              class="field__input"
              v-model="form.name"
              :placeholder="displayPlaceholder(field, '請輸入您的姓名')"
              :class="{ invalid: v$.form.name.$error }"
              :disabled="field.disabled"
            />
            <small v-if="field.memo" class="field__desc">{{
              field.memo
            }}</small>
          </div>
          <!-- name -->

          <!-- mobile_phone -->
          <div v-if="field.type === 'mobile_phone'">
            <label
              class="field__label"
              :class="field.required === true ? 'field--required' : null"
            >
              {{ displayTitle(field, "手機號碼") }}
            </label>
            <div class="d-flex">
              <input
                class="field__input"
                v-model="form.mobile_phone"
                type="tel"
                :class="{ invalid: v$.form.mobile_phone.$error }"
                :placeholder="displayPlaceholder(field, '請輸入您的手機號碼')"
                :disabled="
                  field.disabled || isMobileValidating || mobileIsValid
                "
                @keypress="v$.$touch()"
              />
              <template v-if="getModuleConfig('enable_sms_check')">
              <SharedButton                
                :disabled="
                  !form.mobile_phone ||
                  v$.form.mobile_phone.$error ||
                  isMobileValidating ||
                  mobileIsValid
                "
                class="ml-3 w-auto text-nowrap s-btn-bg-primary"
                @click="sendMobile"
              >
                <template v-if="isMobileValidating && counter > 0">
                  {{ counter }}秒後失效
                </template>
                <template v-else-if="mobileIsValid">已驗証</template>
                <template v-else> 傳送驗證碼 </template></SharedButton
              >
              <button
                  v-if="(!isMobileValidating && !mobileIsValid && $store.state.liffAuth.user.phone_verified_at != null) || isMobileValidating"
                  @click="()=>{
                    isMobileValidating = false;                    
                    mobileIsValid = $store.state.liffAuth.user.phone_verified_at != null
                    form.mobile_phone = $store.state.liffAuth.user.mobile_phone
                  }"
                  class="btn btn-link text-nowrap ml-3"
                >
                  取消
                </button>
                <button
                  v-if="mobileIsValid && !isMobileValidating"
                  @click="mobileIsValid = false"
                  class="btn btn-link text-nowrap ml-3"
                >
                  修改
                </button>
              </template>
            </div>
            <small v-if="field.memo" class="field__desc">{{
              field.memo
            }}</small>
            <div v-if="v$.form.mobile_phone.$error" class="form__error-msg">
              您輸入的格式有誤，請再次確認
            </div>
            <div v-if="mobileValidMessage" class="form__error-msg">
              {{ mobileValidMessage }}
            </div>
            <div v-if="v$.mobileIsValid.$invalid" class="form__error-msg">
              手機號碼未驗証
            </div>

            <div v-if="isMobileValidating" class="code-input d-flex">
              <div>
                <CodeInput
                  :loading="false"
                  :fields="6"
                  :fieldHeight="60"
                  :class="{ invalid: !codeIsValid }"
                  @change="(e) => (verificationCode = e)"
                />
                <div v-if="!codeIsValid" class="form__error-msg">
                  {{ codeValidMessage }}
                </div>
              </div>
              <div class="flex-fill ml-2">
                <SharedButton
                  class="text-nowrap s-btn-bg-primary p-2 my-2"
                  :disabled="verificationCode.length!=6"
                  @click="verifyMobile"
                  >確定</SharedButton
                >                
              </div>
            </div>
          </div>
          <!-- mobile_phone -->

          <!-- email -->
          <div v-if="field.type === 'email'">
            <label
              class="field__label"
              :class="field.required === true ? 'field--required' : null"
            >
              {{ displayTitle(field, "電子信箱") }}
            </label>
            <input
              class="field__input"
              v-model="form.email"
              type="tel"
              :class="{ invalid: v$.form.email.$error }"
              :placeholder="displayPlaceholder(field, '請輸入您的電子信箱')"
              :readonly="field.disabled"
            />
            <small v-if="field.memo" class="field__desc">{{
              field.memo
            }}</small>
            <div v-if="v$.form.email.$error" class="form__error-msg">
              您輸入的格式有誤，請再次確認
            </div>
          </div>
          <!-- email -->

          <!-- date -->
          <div v-if="field.type === 'date'">
            <label
              class="field__label"
              :class="field.required === true ? 'field--required' : null"
            >
              {{ field.title }}
            </label>
            <div
              class="field__select-group"
              :class="{
                invalid:
                  v$.form[`${field.field_key}-year`].$error ||
                  v$.form[`${field.field_key}-month`].$error ||
                  v$.form[`${field.field_key}-day`].$error,
              }"
            >
              <SharedSelect
                v-model="form[`${field.field_key}-year`]"
                :themeColor="themeColor"
                :options="yearOptions"
                :hasBorder="false"
                :disabled="field.disabled"
              />
              <SharedSelect
                v-model="form[`${field.field_key}-month`]"
                :themeColor="themeColor"
                :options="monthOptions"
                :hasBorder="false"
                :disabled="field.disabled"
              />
              <SharedSelect
                v-model="form[`${field.field_key}-day`]"
                :themeColor="themeColor"
                :options="
                  dayOptions(
                    form[`${field.field_key}-year`],
                    form[`${field.field_key}-month`]
                  )
                "
                :hasBorder="false"
                placeholder="日"
                :disabled="field.disabled"
              />
            </div>
            <small v-if="field.memo" class="field__desc">{{
              field.memo
            }}</small>
            <div
              v-if="
                v$.form[`${field.field_key}-year`].$error ||
                v$.form[`${field.field_key}-month`].$error ||
                v$.form[`${field.field_key}-day`].$error
              "
              class="form__error-msg"
            >
              輸入的資料有誤或未填寫，請確認
            </div>
          </div>
          <!-- birthday -->

          <!-- address -->
          <div v-if="field.type === 'address'">
            <label
              class="field__label"
              :class="field.required === true ? 'field--required' : null"
            >
              {{ displayTitle(field, "地址") }}
            </label>
            <div class="field__select-group mb-3 city-selector-set-has-value">
              <SharedSelect
                v-model="form[`${field.field_key}-city`]"
                :hasBorder="false"
                selectClass="county"
                :disabled="field.disabled"
              />
              <SharedSelect
                v-model="form[`${field.field_key}-district`]"
                :hasBorder="false"
                selectClass="district"
                :disabled="field.disabled"
              />
            </div>
            <input
              class="field__input"
              v-model="form[`${field.field_key}-address`]"
              placeholder="請輸入詳細地址"
              :class="{
                invalid:
                  v$.form[`${field.field_key}-address`].$error ||
                  v$.form[`${field.field_key}-district`].$error ||
                  v$.form[`${field.field_key}-city`].$error,
              }"
              :disabled="field.disabled"
            />
            <small v-if="field.memo" class="field__desc">{{
              field.memo
            }}</small>
          </div>
          <!-- address -->

          <!-- text type -->
          <div v-if="field.type === 'text' && field.matched_key">
            <label
              class="field__label"
              :class="field.required === true ? 'field--required' : null"
              >{{ field.title }}</label
            >
            <input
              class="field__input"
              v-model="form[field.field_key]"
              :class="{ invalid: v$.form[field.field_key].$error }"
              :placeholder="displayPlaceholder(field)"
              :disabled="field.disabled"
            />
            <small v-if="field.memo" class="field__desc">{{
              field.memo
            }}</small>
            <div v-if="v$.form[field.field_key].$error" class="form__error-msg">
              輸入的資料有誤或未填寫，請確認
            </div>
          </div>
          <!-- text type -->

          <!-- select type -->
          <div v-if="field.type === 'select' && field.matched_key && !field.provider">
            <label
              class="field__label"
              :class="field.required === true ? 'field--required' : null"
              >{{ field.title }}</label
            >
            <SharedSelect
              v-model="form[field.field_key]"
              :options="field.options"
              :hasBorder="false"
              :placeholder="displayPlaceholder(field)"
              :disabled="field.disabled"
            />
            <small v-if="field.memo" class="field__desc">{{
              field.memo
            }}</small>
            <div v-if="v$.form[field.field_key].$error" class="form__error-msg">
              輸入的資料有誤或未填寫，請確認
            </div>
          </div>
          <!-- select type -->
          <!-- select type is Branch -->
          <div v-else-if="field.type === 'branch' && field.matched_key && field.provider">
            <label
              class="field__label"
              :class="field.required === true ? 'field--required' : null"
              >{{ field.title }}</label
            >
            <div class="row" v-if="branchEdited">
              <div v-for="(level, index) in branchLevel" :key="level" class="col col-5 mr-5">
                <SharedSelect
                  class="mt-2"
                  v-model="temporaryBranch[index]"
                  :options="providerOptions[field.field_key][level].options"
                  :hasBorder="false"
                  :placeholder="displayPlaceholder(field)"
                />
              </div>
            </div>
            <div v-else class="row">
              <div class="col col-5 mr-5">
                <b-form-select
                  class="mt-2"
                  v-model="form[field.field_key]"
                  :options="initBranches"
                  disabled
                />
              </div>
              <div class="col col-2" v-if="!field.disabled">
                <SharedButton class="s-btn-outline-primary" @click="branchEdited = !branchEdited">編輯</SharedButton>
              </div>
            </div>
            <small v-if="field.memo" class="field__desc">{{
              field.memo
            }}</small>
            <div
              v-if="v$.form[field.field_key].$error"
              class="form__error-msg"
            >
              輸入的資料有誤或未填寫，請確認
            </div>
          </div>
          <!-- select type -->
          <!-- radio type -->
          <div v-if="field.type === 'radio' && field.matched_key">
            <label
              class="field__label"
              :class="field.required === true ? 'field--required' : null"
              >{{ field.title }}</label
            >
            <div
              class="s-flex flex-wrap"
              :class="field.is_vertical && 'flex-column'"
            >
              <SharedRadioButton
                v-for="(option, index) in field.options"
                v-model="form[field.field_key]"
                :id="field.title + index"
                :key="field.title + index"
                :value="option.value"
                :name="field.matched_key"
                :disabled="field.disabled"
              >
                {{ option.text }}
              </SharedRadioButton>
            </div>
            <small v-if="field.memo" class="field__desc">{{
              field.memo
            }}</small>
            <div v-if="v$.form[field.field_key].$error" class="form__error-msg">
              輸入的資料有誤或未填寫，請確認
            </div>
          </div>
          <!-- radio type -->

          <!-- checkbox type -->
          <div v-else-if="field.type === 'checkbox' && field.matched_key">
            <label
              class="field__label"
              :class="field.required === true ? 'field--required' : null"
              >{{ field.title }}</label
            >
            <div class="s-flex">
              <!-- <SharedCheckbox
                  v-for="(option, index) in field.options"
                  v-model="form[field.field_key]"
                  :key="field.title + index"
                  :value="option.value"
                >
                  {{ option.text }}
                </SharedCheckbox> -->
              <b-form-checkbox-group
                :options="field.options"
                v-model="form[field.field_key]"
                :stacked="field.is_vertical"
                :disabled="field.disabled"
              ></b-form-checkbox-group>
            </div>
            <small v-if="field.memo" class="field__desc">{{
              field.memo
            }}</small>
            <div v-if="v$.form[field.field_key].$error" class="form__error-msg">
              輸入的資料有誤或未填寫，請確認
            </div>
          </div>
          <!-- checkbox type -->
        </div>
        <!-- fields loop end -->
      </div>
      <div class="reminder">{{ getModuleConfig("reminder_text") }}</div>
      <div class="page__button">
        <MemberButton
          ref="memberButton"
          :button-text="getModuleConfig('button_text') || '確定儲存'"
          :button-url="getModuleConfig('button_url')"
          :type="getModuleConfig('done_action')"
          :disable-default-click="true"
          @custom-click="handleSubmit"
        ></MemberButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Header from "@/components/Page/Liff/Shared/HeaderV3";
import SharedSelect from "@/components/Page/Liff/Shared/Select";
import SharedRadioButton from "@/components/Page/Liff/Shared/RadioButton";
import SharedButton from "@/components/Page/Liff/Shared/Button";
import MemberButton from "@/components/Page/Liff/Shared/MemberButton.vue";
import TwCitySelector from "tw-city-selector";
import dateOptionMixins from "@/mixins/liff/dateOption";
import authApi from "@/apis/liff/v2/auth";
import Loading from "@/components/Page/Liff/Shared/Loading";
import { isValidJsonArray } from "@/utils/jsonHelper";
import _ from "lodash";
import themeColor from "@/mixins/liff/themeColor";
import branchMixin from "@/mixins/Dashboard/branches";
import registerApi from "@/apis/liff/v2/register";
import CodeInput from "vue-verification-code-input";

const TIMEOUT = 120;

export default {
  mixins: [dateOptionMixins, themeColor, branchMixin],

  setup: () => ({ v$: useVuelidate({ $lazy: true }) }),

  components: {
    CodeInput,
    Loading,
    Header,
    SharedSelect,
    SharedRadioButton,
    SharedButton,
    MemberButton,
  },

  data() {
    return {
      showLoading: false,
      themeColor: "#6699CC",
      form: this.setForm(),
      fields: [],
      themeConfigPage:'register',
      providerOptions: {},
      branchId: null,
      temporaryBranch: [],
      branchLevel: [],
      branchEdited: false,
      initBranches: [],
      isLastBranch: false,
      verificationCode: '',
      counter: TIMEOUT,
      timer: null,
      codeIsValid: true,
      mobileIsValid: false,
      isMobileValidating: false,
      mobileValidMessage: null,
      codeValidMessage: null,
    };
  },

  watch: {
    "claimsForWatcher": {
      handler: function (newVal, oldVal) {
        if (newVal.slice(-1)[0] === oldVal.slice(-1)[0]) {
          for (var i = newVal.length - 1; i >= 0; i--) {
            if (newVal[i] !== oldVal[i]) {
              this.temporaryBranch = this.temporaryBranch.slice(0, i+1)
              this.branchLevel = this.branchLevel.slice(0, i+1)
            }
          }
        } else {
          if (newVal.length < oldVal.length) {
            this.branchLevel = this.branchLevel.slice(0, newVal.length+1)
          }
          const checkNullIndex = newVal.indexOf(null)
          if (checkNullIndex !== this.branchLevel.length - 1) {
            if (checkNullIndex !== -1) {
              this.branchLevel = this.branchLevel.slice(0, checkNullIndex + 1)
              this.isLastBranch = false
              if (checkNullIndex !== newVal.length - 1) {
                this.temporaryBranch = this.temporaryBranch.slice(0, checkNullIndex + 1)
              }
            } else {
              this.branchLevel = this.branchLevel.slice(0, newVal.length)
              this.branchId = newVal.slice(-1)[0]
              const keyName = this.fields.find((f) => {
                if (f.type == 'branch') {
                  return f
                }
              }).field_key
              this.form[keyName] = this.branchId
              this.fetchProviderFieldsOption(false)
            }
          } else {
            this.isLastBranch = false
          }
        }
      },
      deep: true
    },
  },

  validations() {
    let form = {};
    let mobileIsValid = {};

    for (let i = 0; i < this.fields.length; i++) {
      let field = this.fields[i];
      // 特殊規則 手機
      if (field.type === "mobile_phone") {
        form.mobile_phone = this.isFieldRequired("mobile_phone")
          ? {
              required,
              mobile: (val) => {
                return /09[0-9]{8}/.test(val);
              },
            }
          : {};

        if (this.getModuleConfig("enable_sms_check")) {
          mobileIsValid = {
            isValid: (val) => {
              return val == true;
            },
          };
        }

        continue;
      }

      // 特殊規則 日期
      if (field.type === "date") {
        form[`${field.field_key}-year`] =
          field.required === true ? { required } : {};
        form[`${field.field_key}-month`] =
          field.required === true ? { required } : {};
        form[`${field.field_key}-day`] =
          field.required === true ? { required } : {};

        continue;
      }

      // 特殊規則 地址
      if (field.type === "address") {
        form[`${field.field_key}-city`] = this.isFieldRequired("address")
          ? { required }
          : {};
        form[`${field.field_key}-district`] = this.isFieldRequired("address")
          ? { required }
          : {};
        form[`${field.field_key}-address`] = this.isFieldRequired("address")
          ? { required }
          : {};
        continue;
      }

      // 特殊規則 分店 不管是不是必要 都要選到最後
      if (field.type === "branch") {
        form[field.field_key] = field.required === true
          ? {
              required,
              branch: () => {
                return this.isLastBranch;
              },
            }
          : {
            branch: () => {
                return this.isLastBranch;
              },
            };
        continue;
      }

      // 一般欄位規則
      form[field.field_key] = field.required === true ? { required } : {};
    }

    return {
      form,
      mobileIsValid,
    };
  },

  computed: {
    ...mapGetters({
      meta: "liffGeneral/meta",
      themeConfig: "liffGeneral/themeConfig",
    }),
    claimsForWatcher() {
      return _.cloneDeep(this.temporaryBranch);
    }
  },

  mounted() {
    this.init();
  },

  methods: {
    isValidJsonArray,
    setForm() {
      //解決動態 validations 必須先定義 form 的 property

      var form = {};
      let fields = this.fetchFields();

      // Refill fields
      fields.forEach((field) => {
        let isExtra = field.matched_key.startsWith("extra.");
        let user = isExtra
          ? this.$store.state.liffAuth.user.extra
          : this.$store.state.liffAuth.user;

        // 特殊規則 地址
        if (field.type === "address") {
          // if (
          //   user[`${field.field_key}-city`] &&
          //   user[`${field.field_key}-city`] !== undefined
          // )
          form[`${field.field_key}-city`] = user[`${field.field_key}-city`];

          // if (
          //   user[`${field.field_key}-district`] &&
          //   user[`${field.field_key}-district`] !== undefined
          // )
          form[`${field.field_key}-district`] =
            user[`${field.field_key}-district`];

          // if (
          //   user[`${field.field_key}-address`] &&
          //   user[`${field.field_key}-address`] !== undefined
          // )
          form[`${field.field_key}-address`] =
            user[`${field.field_key}-address`];

          return;
        }

        // 特殊規則 日期
        if (field.type === "date") {
          const parseDate = new Date(
            this.$route.query[field.matched_key] ||
              field.value ||
              user[field.field_key]
          );

          form[`${field.field_key}-year`] = parseDate.getFullYear();
          form[`${field.field_key}-month`] = parseDate.getMonth() + 1;
          form[`${field.field_key}-day`] = parseDate.getDate();

          return;
        }

        // checkbox
        if (field.type === "checkbox") {
          form[field.field_key] =
            this.$route.query[field.matched_key] ||
            field.value ||
            this.isValidJsonArray(user[field.field_key])
              ? JSON.parse(user[field.field_key])
              : user[field.field_key];

          return;
        }

        // if (user[field.field_key] !== undefined) {
        //其他一般欄位
        form[field.field_key] =
          this.$route.query[field.matched_key] ||
          field.value ||
          user[field.field_key];

        return;
        //}
      });

      return { ...form };
    },
    async init() {
      // 依照 module_config 顯示 Editable fields
      // 增加 "hidden" 屬性

      // 這裡負責把後端存起來的 user 資料依照本次 module_config 決定的欄位，一個一個填回去 v-model
      //const user = this.$store.state.liffAuth.user;
      //let form = {};

      this.fields = this.fetchFields();
      this.fetchProviderFieldsOption(true)

      //Mobile Valid

      this.mobileIsValid =
        this.getModuleConfig("enable_sms_check") &&        
        this.$store.state.liffAuth.user.phone_verified_at;

      // Refill fields
      this.fields.forEach(async (field) => {
        let isExtra = field.matched_key.startsWith("extra.");
        let user = isExtra
          ? this.$store.state.liffAuth.user.extra
          : this.$store.state.liffAuth.user;

        // 地址也特殊規格處理
        if (field.type === "address") {
          new TwCitySelector({
            el: ".city-selector-set-has-value",
            elCounty: ".county",
            elDistrict: ".district",
            countyValue: user[`${field.field_key}-city`],
            districtValue: user[`${field.field_key}-district`],
          });

          return;
        }
      });

      //this.form = form;

      // 這個要等 form 被賦值後才能執行，不然會找不到 form.city & form.district

      // for (let i = 0; i < this.fields.length; i++) {
      //   if (this.fields[i].type === "address") {
      //     this.createCitySelector(this.fields[i].field_key);
      //   }
      // }
    },
    fetchFields() {
      let fields = this.getModuleConfig("member_fields");

      fields = _.map(fields, (field) => {
        let field_key;
        if (field.matched_key) {
          field_key = field.matched_key.startsWith("extra.")
            ? field.matched_key.substring(6)
            : field.matched_key;
        } else {
          field_key = field.type;
        }

        return {
          ...field,
          field_key,
        };
      });

      return _.sortBy(fields, "order");
    },
    isFieldShow(key) {
      return this.fields.some((field) => field.type === key);
    },

    isFieldRequired(key) {
      return this.fields.some(
        (field) => field.type === key && field.required == true
      );
    },

    // isFieldDisabled(field) {
    //   if (field.can_edit == undefined) return true;
    //   return !field.can_edit;
    // },

    displayPlaceholder(field, defaultMessage) {
      if (field.placeholder) return field.placeholder;
      if (defaultMessage) return defaultMessage;
      if (field.title) return "請輸入" + field.title;
      return "";
    },

    displayTitle(field, defaultMessage) {
      if (field.title) return field.title;
      if (defaultMessage) return defaultMessage;
      return "";
    },

    // createCitySelector(key) {
    //   new TwCitySelector({
    //     el: ".city-selector-set-has-value",
    //     elCounty: ".county",
    //     elDistrict: ".district",
    //     countyValue: this.form[`${key}-city`] ?? null,
    //     districtValue: this.form[`${key}-district`] ?? null,
    //   });
    // },
    async handleSubmit() {
      const result = await this.v$.$validate();
      if (!result) {
        return;
      }
      try {
        this.showLoading = true;

        let payload = { extra: {} };
        //let extraRules = [];

        this.fields.forEach((field) => {
          let isExtra = field.matched_key.startsWith("extra.");
          let data = isExtra ? payload.extra : payload;

          //let fieldConfig = this.fields[i];

          // 如果是特殊類型的欄位
          if (field.type === "date") {
            data[field.field_key] = `${this.form[`${field.field_key}-year`]}-${
              this.form[`${field.field_key}-month`]
            }-${this.form[`${field.field_key}-day`]}`;
            return;
          }

          if (field.type === "address") {
            data[`${field.field_key}-city`] =
              this.form[`${field.field_key}-city`];
            data[`${field.field_key}-district`] =
              this.form[`${field.field_key}-district`];
            data[`${field.field_key}-address`] =
              this.form[`${field.field_key}-address`];
            return;
          }

          // 一般欄位就不處理
          data[field.field_key] = this.form[field.field_key];
        });

        await authApi.updateMeV2(payload);
        await this.$store.dispatch("liffAuth/fetchMe");

        this.$swal
          .fire({ title: "更新成功", type: "success" })
          .then((result) => {
            if (result) {
              this.$refs.memberButton.goNext();
            }
          });
      } catch (error) {
        console.log("MemberCenterEdit", error);
      } finally {
        this.showLoading = false;
      }
    },
    getModuleConfig(key) {
      return this.$store.getters["liffModule/getConfig"]("member_edit", key);
    },
    async fetchProviderFieldsOption(isInit) {
      this.fields.filter((f) => { return f.provider }).map(async (field) => {
        const providerSetting = field.provider.split(':');
        if (isInit) {
          if (this.form[field.field_key]) {
            // const { data } = registerApi.getSingleBranch(user.extra[fieldConfig.field_key])
            const { data } = await registerApi.getSingleBranch(this.form[field.field_key])
            this.initBranches = [{
              value: data.data.id,
              text: `${data.data.branch_code} ${data.data.name}`,
            }]
          }
          this.providerOptions[field.field_key] = []
          let response = null
          if (providerSetting[1] == 'all') {
            // 全部分店
            response = await registerApi.getBranches({ type: 'all' });
          } else if (providerSetting[1] == 'level') {
            // 階級獲取 只是從parent_id - null
            response = await registerApi.getBranches({ type: 'level', branch_id: null });
          } else {
            // 階級獲取 從特定分店開始
            response = await registerApi.getBranches({ type: 'specific', branch_code: providerSetting[1] });
          }
          const source = response.data.data
          const level = source[0].level
          this.providerOptions[field.field_key][level] = {}
          this.providerOptions[field.field_key][level].options = this.sortBranch(source, source[0]['org_id'])
          this.branchLevel.push(level)
        } else {
          if ((providerSetting.length === 2) || providerSetting[2] === 'true') {
            this.providerOptions[field.field_key][this.branchLevel.slice(-1)[0] + 1] = {}
            const response = await registerApi.getBranches({ type: 'level', branch_id: this.branchId });
            const source = response.data.data
            if (source) {
              const level = source[0].level
              this.providerOptions[field.field_key][level] = {}
              this.providerOptions[field.field_key][level].options = this.sortBranch(source, source[0]['org_id'])
              if (level < this.branchLevel.slice(-1)[0]) {
                this.branchLevel[this.branchLevel.length - 1] = level
              } else if (this.branchLevel.indexOf(level) === -1) {
                this.branchLevel.push(level)
              }
              this.isLastBranch = false
            } else {
              if (this.branchLevel.length > this.temporaryBranch.filter(Boolean).length) {
                this.branchLevel = this.branchLevel.slice(0, this.temporaryBranch.filter(Boolean).length)
              }
              this.isLastBranch = true
            }
          } else {
            this.isLastBranch = true
          }
        }
        this.$forceUpdate();
      })
    },
    async sendMobile() {
      this.startTimer();
      await registerApi
        .sendPhone(this.form.mobile_phone)
        .then((response) => {
          if (response.data.status == "success") {
            this.isMobileValidating = true;
            this.mobileValidMessage = null;
          }
        })
        .catch((error) => {
          this.mobileValidMessage = error.response.data.message;
        });
    },
    async verifyMobile() {
      await registerApi
        .verifyByMobile(this.verificationCode, this.form.mobile_phone)
        .then((response) => {
          if (response.data.status == "success") {

            this.$store.dispatch("liffAuth/fetchMe");

            this.codeIsValid = this.mobileIsValid = true;
            this.isMobileValidating = false;
            this.codeValidMessage = null;
          }
        })
        .catch((error) => {
          this.codeIsValid = this.mobileIsValid = false;
          this.codeValidMessage = error.response.data.message;
        });

      //this.mobileIsValid = false;
    },

    startTimer() {
      this.clearTimer();
      this.counter = TIMEOUT;
      this.timer = window.setInterval(() => {
        this.counter--;

        if (this.counter > 0) {
          return;
        }

        this.clearTimer();
      }, 1000);
    },
    clearTimer() {
      if (!this.timer) {
        return;
      }

      window.clearInterval(this.timer);
      this.timer = null;
      this.isMobileValidating = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  padding: 20px 10px;
  background: #ffffff;
  &__title {
    margin-bottom: 20px;
    span {
      color: #6699cc;
      font-weight: 600;
      font-size: 14px;
    }
  }
  &__submit-button {
    margin: 12px;
    margin-top: 57px;
  }
  &__error-msg {
    color: #fe0000;
    font-size: 12px;
    margin-top: 8px;
  }
}

.field {
  &:not(:last-of-type) {
    margin-bottom: 24px;
  }
  &__label {
    font-weight: 400;
    font-size: 14px;
    color: var(--liff-primary_text_color);
    margin-bottom: 8px;
    display: block;
  }

  &__desc {
    color: var(--liff-secondary_text_color);
    font-size: 12px;
    line-height: 1;
    margin-top: 4px;
  }

  &__input {
    font-weight: 500;
    font-size: 16px;
    color: var(--liff-primary_text_color);
    border: none;
    border-bottom: 1px solid #e5e5ea;
    background: transparent;
    padding-bottom: 8px;
    width: 100%;
    border: 1px solid #e5e5ea;
    border-radius: 5px;
    padding: 8px 12px;
    &::placeholder {
      color: #b7b7b7;
    }
    &:focus {
      border: 1px solid var(--liff-primary_text_color);
    }
    &.invalid {
      border-bottom: 1px solid #fe0000;
    }
    &:read-only {
      background-color: #f0f0f0;
    }
  }

  &--required {
    &:after {
      content: "*";
      position: relative;
      font-family: "PingFang TC";
      color: var(--s-primary);
      margin-left: 2px;
    }
  }

  &__select-group {
    display: flex;
    > * {
      width: 100%;
      &:not(:last-of-type) {
        margin-right: 8px;
      }
    }
  }
}

.reminder {
  font-size: 12px;
  color: var(--liff-secondary_text_color);
  margin: 12px;
  a {
    text-decoration: underline;
  }
}

.code-input {
  margin-bottom: 32px;
  ::v-deep .react-code-input-container {
    margin: auto;
    text-align: center;
    width: auto !important;
    &.invalid input {
      border: 1px solid #fe0000;
    }
  }
  ::v-deep input {
    border: 1px solid #636366 !important;
    border-radius: 10px !important;
    font-size: 18px;
    font-weight: 600;
    width: 14% !important;
    max-width: 45px;
    font-family: PingFang TC;
    color: var(--primary-color) !important;

    &:not(:last-of-type) {
      margin-right: 8px;
    }

    &:focus {
      border: 1px solid var(--liff-primary_text_color);
      caret-color: var(--liff-primary_text_color);
    }
    &:focus + input {
      border: 1px solid #636366;
    }
  }
}
</style>
