import { render, staticRenderFns } from "./MemberCenterHome.vue?vue&type=template&id=204a7f4d&scoped=true&"
import script from "./MemberCenterHome.vue?vue&type=script&lang=js&"
export * from "./MemberCenterHome.vue?vue&type=script&lang=js&"
import style0 from "./MemberCenterHome.vue?vue&type=style&index=0&id=204a7f4d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "204a7f4d",
  null
  
)

export default component.exports